@use '@vst/beam/index.scss';
@use '@mfe/shared-css/global' as *;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow: auto;
  overscroll-behavior: none;
}

body {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: color(gray_100);
}

#root {
  display: block;
}

#usage-root {
  justify-content: center;
  width: 100%;
}

p {
  margin: 0;
  padding: 0;
}

.button {
  background-color: transparent;
  border: 1px solid white;
  padding: 11px 0 13px;
  border-radius: 100px;
  min-width: 135px;
  cursor: pointer;
}

// Useful for replicating the beam link component when you don't have an actual link to open
.beamLink {
  cursor: pointer;
  color: color(blue_600);
  text-decoration: underline;

  &:hover,
  &:active {
    text-decoration: none;
    color: color(blue_700);
  }
}
